const proxy = {
  url: {
    API_BASE_URL: window.location.origin+"/api"
  }
}

const dev = {
  url: {
    API_BASE_URL: 'http://localhost:8080'
  }
}




export const config = process.env.NODE_ENV === 'development' ? dev : proxy